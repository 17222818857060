import Agent from "../components/Agent/Agent";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
const AgentPage = () => {
  return (
    <div>
      <Header />
      <Agent />
      <Footer />
    </div>
  );
};
export default AgentPage;
