import Contacts from "../components/Contact/Contact";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
const Contact = () => {
  return (
    <div>
      <Header />
      <Contacts />
      <Footer />
    </div>
  );
};
export default Contact;
