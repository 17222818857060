import "./Question.css";
import Help from "../Image/Help1R.png";
import Verified from "../Image/Verified2R.png";
import Query from "../Image/Query builder3R.png";
import Pending from "../Image/Pending4R.png";
import History from "../Image/History5R.png";
import Space from "../Image/Backspace6R.png";
import Admin from "../Image/Admin panel settings7R.png";
import Flip from "../Image/Flip camera android8R.png";
import Device from "../Image/Device unknown9R.png";
import Disturb from "../Image/Do not disturbR.png";
import Toggle from "../Image/History toggle off1.png";
import Autorenew from "../Image/Autorenew2.png";
import Announcement from "../Image/Announcement3.png";
import Call from "../Image/Call4.png";
import Wifi from "../Image/Wifi tethering5.png";
import Folder from "../Image/Folder open6.png";
import Warning from "../Image/Warning7.png";
import Account from "../Image/Account circle8.png";
import Add from "../Image/Add business9.png";
import Close from "../Image/Close10.png";
import Wash from "../Image/Wash11.png";
import Trending from "../Image/Trending up12.png";
const Question = () => (
  <div className="cont">
    <div className="faq-header">
      <p id="p-1">Frequently Asked Questions (FAQ)</p>
      <em>
        {" "}
        <h3>What you should know</h3>{" "}
      </em>
      <div className="btn-cont">
        {" "}
        <a href="/contact"> <button>Contact Us</button>{" "} </a>
      </div>
    </div>

    <div className="que-cont">

      <div className="subque-cont">
        <div className="align">
          <img src={Help} alt="" />
          <h3>What is MegazApp?</h3>
        </div>
        <p>
          MegazApp is your number one bill paying platform available in Nigeria.
          We help you convert your excess airtime to cash with very easy few
          steps. It also has a platform for agents where you can register and
          make substantial commission. We offer you top notch security so all
          your transactions anddata is safe from cyber threats.
        </p>
      </div>

      <div className="subque-cont">
        <div className="align">
          <img src={Toggle} alt="" />
          <h3>Is There A Stipulated Time for Payment?</h3>
        </div>
        <p>
          Airtime to cash service operates 24/7. However we pay from 8am to 11pm
          on weekdays and 12 noon to 11pm on weekends and public holidays.
        </p>
      </div>

      <div className="subque-cont">
        <div className="align">
          <img src={Verified} alt="" />
          <h3>Is This Platform Legitimate?</h3>
        </div>
        <p>
          MegazApp is a registered Nigerian company with registered License RC:
          7201661. It is 100% legitimate.{" "}
        </p>
      </div>

      <div className="subque-cont">
        <div className="align">
          <img src={Autorenew} alt="" />
          <h3>What Are The Rates For Converting Airtime To Cash</h3>
        </div>
        <p>
          MegazApp does not have a fixed rate for airtime to cash conversion.
          All networks rates are usually not the same and are subject to change.
          However, we will always offer the best possible rates{" "}
        </p>
      </div>

      <div className="subque-cont">
        <div className="align">
          <img src={Query} alt="" />
          <h3>What Are Your Hours Of Operation?</h3>
        </div>
        <p>
          We operate 24/7, we are always available to serve you better. However
          in the event of server maintenance or any of such activities you may
          experience difficulty or inability to use our service. When such
          arises we will keep you updated.{" "}
        </p>
      </div>

      <div className="subque-cont">
        <div className="align">
          <img src={Announcement} alt="" />
          <h3>Will The Airtime Deduct From My Balance Automatically?</h3>
        </div>
        <p>
          No it will not. You will need to transfer the airtime to the provided
          number manually. The required steps will be shown to you as you
          perform the transaction.{" "}
        </p>
      </div>

      <div className="subque-cont">
        <div className="align">
          <img src={Pending} alt="" />
          <h3>Do You Have An App And Where Can I Get It? </h3>
        </div>
        <p>
          MegazApp has no mobile app yet. Mobile app for android and IOS users
          will be available soonest.
        </p>
      </div>

      <div className="subque-cont">
        <div className="align">
          <img src={Call} alt="" />
          <h3>Can I Buy Airtime On MegazApp?</h3>
        </div>
        <p>
          Yes. You can buy airtime for any network in Nigeria at the best
          possible rates 24/7 from us.{" "}
        </p>
      </div>

      <div className="subque-cont">
        <div className="align">
          <img src={History} alt="" />
          <h3>
            How long would it take for me to be credited after withdrawal from
            my wallet?
          </h3>
        </div>
        <p>
          As soon as you withdraw from your wallet you should be credited within
          3 to 15 minutes of your withdrawal.
        </p>
      </div>

      <div className="subque-cont">
        <div className="align">
          <img src={Wifi} alt="" />
          <h3>Can I Buy Data On MegazApp?</h3>
        </div>
        <p>
          Yes. You can buy data for any network in Nigeria at the best possible
          rates 24/7 from us.{" "}
        </p>
      </div>

      <div className="subque-cont">
        <div className="align">
          <img src={Space} alt="" />
          <h3>
            What happens if my transaction is rejected of fails to go through?
          </h3>
        </div>
        <p>
          As much as we strive for perfection, there is still a possibility for
          failed transactions. Most times when this occurs you will be auto
          refunded. However, for further assistance you can contact the support
          for immediate help.
        </p>
      </div>

      <div className="subque-cont">
        <div className="align">
          <img src={Folder} alt="" />
          <h3>What Data Packages Can I Get On MegazApp?</h3>
        </div>
        <p>You can get, SME data, CDG data, Data Gifting On MegazApp.</p>
      </div>

      <div className="subque-cont">
        <div className="align">
          <img src={Admin} alt="" />
          <h3>What happens if my MegazApp account is hacked?</h3>
        </div>
        <p>
          If you notice any irregularities in your account, contact the support
          team immediately for resolution.{" "}
        </p>
      </div>

      <div className="subque-cont">
        <div className="align">
          <img src={Warning} alt="" />
          <h3>What happens if I Send Airtime or Data To A Wrong Number?</h3>
        </div>
        <p>
          We advice you check the number you are loading properly before
          loading. However, if you send to an invalid number you will be
          refunded immediately but if you send to a valid number you probably
          will not be getting your funds back.
        </p>
      </div>

      <div className="subque-cont">
        <div className="align">
          <img src={Flip} alt="" />
          <h3>
            How Long Would It Take For Me To Be Credited After Converting
            Airtime To Cash?
          </h3>
        </div>
        <p>
          As soon as you have sent the airtime to the provided phone number you
          should be credited within 3 to 15 minutes. However it may take longer
          if there is a bank delay.{" "}
        </p>
      </div>

      <div className="subque-cont">
        <div className="align">
          <img src={Account} alt="" />
          <h3>What Is The MegazApp Agent All About?</h3>
        </div>
        <p>
          As an agent you earn the privilege to partner with MegazApp and make
          substantial profit on every transaction.
        </p>
      </div>

      <div className="subque-cont">
        <div className="align">
          <img src={Device} alt="" />
          <h3>What Networks Can You Convert? </h3>
        </div>
        <p>
          You can convert all networks in Nigeria. Mtn, Airtel, Glo and 9mobile.
          However you can discover a network is not available at a certain time,
          this is due to a network down time from the network provider. You can
          check back after some time.{" "}
        </p>
      </div>

      <div className="subque-cont">
        <div className="align">
          <img src={Add} alt="" />
          <h3>Why Should I Become A MegazApp Agent?</h3>
        </div>
        <p>
          As an Agent you get Access to most of our services at a cheaper price.
          We provide you a platform that helps you provide your customers with
          the best possible services.{" "}
        </p>
      </div>

      <div className="subque-cont">
        <div className="align">
          <img src={Disturb} alt="" />
          <h3>Can I Convert VTU/ETOPUP?</h3>
        </div>
        <p>
          We do not accept VTU/Etopup. The airtime has to be loaded on a private
          line before it is transferred to us. This measure is put in place to
          prevent fraud.
        </p>
      </div>

      <div className="subque-cont">
        <div className="align">
          <img src={Close} alt="" />
          <h3>
            Can I Own An Enduser and An Agent Account With The Same Details?
          </h3>
        </div>
        <p>
          No you can not. However, you can always upgrade from an enduser
          Account to an agent account.{" "}
        </p>
      </div>

      <div className="subque-cont">
        <div className="align">
          <img src={Disturb} alt="" />
          <h3>Can I Convert Bonus?</h3>
        </div>
        <p>
          We do not accept bonus for any network. Has this cannot be sent to a
          third party or used for data subscriptions.{" "}
        </p>
      </div>

      <div className="subque-cont">
        <div className="align">
          <img src={Wash} alt="" />
          <h3>How Much Will I Pay To Register As An Agent?</h3>
        </div>
        <p>Registration and upgrade is free! </p>
      </div>

      <div className="subque-cont">
        <div className="align">
          <img src={Disturb} alt="" />
          <h3>Can I Convert Recharge Card Pin?</h3>
        </div>
        <p>
          We do not accept recharge card pin. Load it on your private line then
          transfer it to us.
        </p>
      </div>

      <div className="subque-cont">
        <div className="align">
          <img src={Trending} alt="" />
          <h3>How do I increase my daily limit?</h3>
        </div>
        <p>Upgrade your account to increase your daily limit.</p>
      </div>

    </div>
  </div>
);
export default Question;
