import React, { useState } from 'react';
import './Deactivate.css';
import axios from 'axios';

const Deactivate = () => {
    const [isOverlayVisible, setIsOverlayVisible] = useState(false);
    const [isDeleted, setIsDeleted] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
  
    const handleDeactivateClick = () => {
      setIsOverlayVisible(true);
    };
  
    const handleConfirmDelete = async () => {
      try {
        const response = await axios.post('https://megaz-dev.qudra.io/api/v1/users/deactivate-account', {
          email,
          password,
        });
  
        if (response.status === 200) {
          setIsDeleted(true);
          setTimeout(() => {
            setIsOverlayVisible(false);
          }, 2000); 
        }
      } catch (error) {
        setErrorMessage('Failed to deactivate account. Please check your credentials and try again.');
        console.error('Error deactivating account:', error);
      }
    };
  
    return (
      <div>
            <div className="deactivation-container">
        <h2>Account Deactivation</h2>
        <p>We're sorry to see you go. Please confirm your details and proceed with deactivation.</p>
  
        <div className="form-group">
          <label htmlFor="email">Email:</label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
  
        <div className="form-group">
          <label htmlFor="password">Password:</label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
  
        {errorMessage && <p className="error-message">{errorMessage}</p>}
  
        <button className="deactivate-button" onClick={handleDeactivateClick}>
          Deactivate Account
        </button>
  
        {isOverlayVisible && (
          <div className="overlay">
            <div className="overlay-content">
              {isDeleted ? (
                <div>
                  <h3>Account Successfully Deleted</h3>
                  <p>Your account has been successfully deleted.</p>
                </div>
              ) : (
                <div>
                  <h3>Please Confirm Deactivation</h3>
                  <p>Are you sure you want to deactivate your account?</p>
                  <button className="confirm-button" onClick={handleConfirmDelete}>
                    Yes
                  </button>
                  <button
                    className="cancel-button"
                    onClick={() => setIsOverlayVisible(false)}
                  >
                    No
                  </button>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      </div>
    );
  };

export default Deactivate;
