import "./BillPaying.css";
import paymentImg from "../containers/images/transaction.png";
import HandR from "../containers/images/Hand Right.png";

const BillPaying = () => {
  return (
    <div className="bg-wrapper">
      <div className="home-bg">
        <p id="bg-font">The bill-Paying app for everyone.</p>
        <p id="smaller-font">
          Convert your airtime to cash, buy airtime and data at a very
          affordable rate instantly at any time of the day with{" "}
          <strong>MegazApp.</strong>
        </p>
        <div className="home-img">
          <img
            className="div-app-badges"
            alt="Div app badges"
            src="https://cdn.animaapp.com/projects/64f7ab0eb3dacef387997f7c/releases/64ff007c7499d3f06e2f65e2/img/div-app--badges.svg"
          />
        </div>
        <div className="thumb">
          <img src={HandR} id="HandRight" alt="HandRight" />

          <p>New to MegazApp web?</p>
          <a href="/create" className="blue-btn">
            Create Account
          </a>
        </div>
      </div>

      <div className="imgCont">
        <div id="first-circle">
          <div id="second-circle">
            <div id="third-circle"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BillPaying;
