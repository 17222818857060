import "./Create.css";
import phoneImage from "../../Assets/phone.png";
import Logo from "../Logo/Logo";
import { FaRegEye } from "react-icons/fa6";
import { useState } from "react";
import { Link } from "react-router-dom";
const Create = () => {
  const [isShowPassword, setIsShowPassword] = useState(false);
  const [isShowConfirmPassword, setIsShowConfirmPassword] = useState(false);
  return (
    <div className="create">
      <div className="form-div">
        <Logo />
        <div className="create-form">
          <h3>CREATE AN ACCOUNT</h3>
          <p>Enter your details below</p>
          <div className="element-grp">
            <div className="form-element">
              <input type="text" placeholder="Full Name" />
              <input type="text" placeholder="Username" />
              <input type="email" placeholder="Email Address" />
              <input type="tel" placeholder="Phone Number" />
              <div className="full-width">
                <div className="input-div">
                  <input
                    type={isShowPassword ? "text" : "password"}
                    placeholder="Password"
                  />
                </div>
                <div
                  className="eye"
                  onClick={() => setIsShowPassword(!isShowPassword)}
                >
                  <FaRegEye />
                  {/* {isShowPassword ? "" :  <FaRegEye />} */}
                </div>
              </div>

              <div className="full-width">
                <div className="input-div">
                  <input
                    type={isShowConfirmPassword ? "text" : "password"}
                    placeholder="Verify Password"
                  />
                </div>
                <div
                  className="eye"
                  onClick={() =>
                    setIsShowConfirmPassword(!isShowConfirmPassword)
                  }
                >
                  <FaRegEye />
                </div>
              </div>
            </div>
            <div className="createIn">
              <input type="checkbox" />
              <p>
                I agree to the{" "}
                <span id="colored-text">terms and conditions</span>
              </p>
            </div>
            <button>Create Account</button>
            <p className="acn">
              Already have an account? Login{" "}
               <span id="colored-text">
               <Link to="/login" className="lik">
               {" "}
                here! </Link>  
                </span> 
            </p>
          </div>
        </div>
      </div>
      <img className="phoneImage" src={phoneImage} alt="" />
    </div>
  );
};

export default Create;
