import "./Deal.css";
import CommonDiv from "../CommonDiv/CommonDiv";
const Deal = () => {
  return (
    <div className="deal">
      <CommonDiv
        title="Best Data Deals"
        content="In a world where mobile data is a fundamental enabler of our digital age,
enhancing communication, access to information, productivity and 
entertainment while also playing a vital role in emergency situations and
social inclusions and economic development. MegazApp offers you the 
access to the best data deals for all networks in Nigeria 24/7."
      />
      <div className="form">
        <div className="container">
          <div className="purchase">
            <label>Choose Network:</label>
            <select>
              <option value="" selected>
                - Select a Level -
              </option>
            </select>
          </div>
          <br />
          <div className="purchase">
            <label>Data Plan:</label>
            <select>
              <option value="" selected>
                - Percentage discount in price -
              </option>
            </select>
          </div>
          <div className="amount">
            Amount: <b>NGN 0.00</b>
          </div>
          <button className="purchase-btn">Buy Cheap Data</button>
        </div>
      </div>
    </div>
  );
};
export default Deal;
