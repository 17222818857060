import "./ConvertCash.css";
import AppleImage from "../../Assets/AppleStore.png";
import GoogleImage from "../../Assets/GoogleStore.png";
const ConvertCash = () => {
  return (
    <div className="convert-body">
      <div className="convert">
        <div className="con-cash">
          <h1>Convert Airtime to Cash.</h1>

          <p>
            Mistakenly over- recharged? <br />
            Having excess airtime on your line and need cash? <br />
            Received airtime as gift?
          </p>

          <p>
            You can always convert your airtime to cash using the convert
            airtime to cash service. We give you very good rates and deliver
            instantly. You can request for the money to be sent directly to your
            account or used in funding your wallet. As funds in your wallet you
            can purchase any of our products.
          </p>

          <div className="app-link-conv">
            <img src={AppleImage} alt="apple" />
            <img src={GoogleImage} alt="google" />
          </div>
        </div>

        <div className="form">
          <div className="containerr">
            <div className="purchase">
              <label>Network Provider</label>
              <select>
                <option value="" selected>
                  - Select Network Provider -
                </option>
              </select>
            </div>
            <div className="amountt">
              Conversion Rate: <b>0%</b>
            </div>

            <br />

            <div className="purchase">
              <label>Enter Amount</label>
              <input type="text" placeholder="" />
            </div>
            <div className="amountt">
              You will recieve: <b>NGN 0</b>
            </div>
            <button className="purchase-btn">Convert Airtime Now</button>
          </div>
        </div>
      </div>
      <div className="last-word">
        <p>VTU/Etopup is not accepted &#215;</p>
        <p>Bonus is not accepted &times;</p>
        <p>Recharge card pin is not accepted &#xD7;</p>
      </div>
    </div>
  );
};
export default ConvertCash;
