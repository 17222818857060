import "./Login.css";
import phoneImage from "../../Assets/phone.png";
import Logo from "../Logo/Logo";
import { FaRegEye } from "react-icons/fa6";
import { useState } from "react";
import { Link } from "react-router-dom";
const Login = () => {
  const [isShowPassword, setIsShowPassword] = useState(false) 
  return (
    <div className="login">
      <div className="form-div">
        <Logo />
        <div className="login-form">
          <h3>LOGIN</h3>
          <p>Enter your details below</p>
          <div className="element-grp">
            <div className="form-element">
              <label>Username or Email</label>
              <input type="text" placeholder="Your username or email" />
            </div>
            <div className="form-element">
              <select>
                <option value="" selected>
                  - ROLE -
                </option>
                <option value="">End User</option>
                <option value="">Agent</option>
              </select>
            </div>
            <div className="form-element">
              <label>Password</label>
              <div className="full-width">
                <div className="input-div">
                <input type={isShowPassword ? "text" : "password"} placeholder="Your Password"/> 
                </div>
                <div className="eye" onClick={() => setIsShowPassword(!isShowPassword)}>
                <FaRegEye />                 
                </div>
              </div>
            </div>
            <p className="forget-pwd">Forget Password?</p>
            <div className="loggedIn">
              <input type="checkbox" />
              <p>Keep me logged in.</p>
            </div>
            <button>Login</button>
            <p className="acn">
              Don't have an account? Create an account
              <Link to="/create" className="lik">
               {" "}
                here! </Link>
            </p>
          </div>
        </div>
      </div>
      <img className="phoneImage" src={phoneImage} alt="" />
    </div>
  );
};

export default Login;
