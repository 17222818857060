import AppleImage from "../../Assets/AppleStore.png";
import GoogleImage from "../../Assets/GoogleStore.png";
import "./CommonDiv.css"
const CommonDiv = (props) => {
    return (
        <div className="common-div">
            <h1>{props.title}</h1>
            <div className="content">
                <p>{props.content}</p>
            </div>
            <div className="download-link">
                    <img src={AppleImage} alt="apple" />
                    <img src={GoogleImage} alt="google" />
            </div>
        </div>
    )
}
export default CommonDiv;