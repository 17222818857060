import React from 'react'
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import Deactivate from '../components/Deactivate/Deactivate';

const AccountDeactivation = () => {
  return (
    <div>
        <Header />
        <Deactivate />
        <Footer />
    </div>
  )
}

export default AccountDeactivation