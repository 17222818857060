import Questions from "../components/Question/Question";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
const Question = () => {
  return (
    <div>
      <Header />
      <Questions />
      <Footer />
    </div>
  );
};
export default Question;
