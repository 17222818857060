import "./About.css";
import AppleImage from "../../Assets/AppleStore.png";
import GoogleImage from "../../Assets/GoogleStore.png";
import { Link } from "react-router-dom";
const About = () => {
  return (
    <div className="about-body">
      <h1>We are MegazApp </h1>
      <h1>Technology.</h1>
      <div className="about-content">
        <h4>
          <em>
            We are changing the FinTech sector <br /> one transaction at a time.
          </em>
        </h4>
        <p>
          MegazApp is a reliable and secure platform that gives you the <br />{" "}
          best possible experience while you buy airtime, data and <br />{" "}
          convert airtime to cash for all networks in Nigeria. We also allow{" "}
          <br /> you to easily oay for your electricity bill and cable
          subscriptions. <br /> All in one App.
        </p>
        <h5>
          For more information{" "}
          <Link to="/contact" className="lik">
            {" "}
            <strong className="strong-cont">Contact Us</strong>
          </Link>
        </h5>
      </div>
      <div className="about-link">
        <img src={AppleImage} alt="apple" />
        <img src={GoogleImage} alt="google" />
      </div>
    </div>
  );
};
export default About;
