import Header from "../components/Header/Header";
import BillPaying from "../components/BillPaying/BillPaying";
import Service from "../components/Service/Service";
import Choose from "../components/Choose/Choose";
import Footer from "../components/Footer/Footer";
const LandingPage = () => {
  return (
    <div>
      <Header />
      <BillPaying />
      <Service />
      <Choose />
      <Footer />
    </div>
  );
};
export default LandingPage;
