import Logo from "../Logo/Logo";
import { Link } from "react-router-dom";
import React, { useRef, useState } from 'react';
import "./Header.css";
import Close from "../Image/Close10.png";
import menu from "../Image/menu.png";
const Header = () => {
const navRef =useRef();
  const [isHovered, setIsHovered] = useState(false);
  const handleHover = () => {
    setIsHovered(!isHovered);
  };
  let show = () => {
    console.log("showing")
    navRef.current.classList.toggle("responsive_nav");
  }
  return (
    <div>
      <div className="header">
        <Logo />
        <div className="nav" ref={navRef}>
        <div className="dropdown" onMouseEnter={handleHover} onMouseLeave={handleHover}>
            Products &#x25BE;
            {/* Dropdown content */}
            <div className="dropdown-content">
              <div className="drop-elem">
                <a href="/convertcash">Airtime to Cash</a>
              </div>
              <div className="drop-elem">
                <a href="/bestdeal">Data </a>
              </div>
              <div className="drop-elem">
                <a href="/">Cable Subscription</a>
              </div>
              <div className="drop-elem">
                <a href="/airtimerate">Airtime</a>
              </div>
              <div className="drop-elem">
                <a href="/">Power Subscription </a>
              </div>
            </div>
          </div>
          <div className="link">
            <div className="text-wrapper-2">
              <Link to="/agent" className="lik">
                {" "}
                Agent
              </Link>
            </div>
          </div>
          <div className="link">
            <div className="text-wrapper-2">
              <Link to="/question" className="lik">
                {" "}
                FAQ
              </Link>
            </div>
          </div>
          <div className="link">
            <div className="text-wrapper-2">
              <Link to="/about" className="lik">
                {" "}
                About
              </Link>
            </div>
          </div>
          <div className="text-wrapper-2">
            <Link to="/contact" className="lik">
              {" "}
              Contact Us
            </Link>
          </div>
          <div className="div-wrapper">
            <div className="text-wrapper-4">
              <Link to="/login" className="lik">
                {" "}
                Login
              </Link>
            </div>
          </div>
          <div className="link-2">
            <div className="text-wrapper-5">
              <Link to="/create" className="like">
                {" "}
                Create Account
              </Link>
            </div>
          </div>
          {/* </div> */}
        </div>
              <button className="nav-btn nav-close-btn" onClick={show}><img className="img-btn" src={menu} alt="close" /></button>
      </div>
    </div>
  );
};
export default Header;
