import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import LandingPage from "./Pages/LandingPage";
import Login from "./components/Login/Login";
import Create from "./components/Create/Create";
import Contact from "./Pages/Contact";
import AboutPage from "./Pages/AboutPage";
import AgentPage from "./Pages/AgentPage";
import Airtime from "./Pages/AirtimeRate";
import BestDeal from "./Pages/BestDeal";
import Question from "./Pages/Question";
import ConvertCash from "./Pages/ConvertCash";
import AccountDeactivation from "./Pages/AccountDeactivation";
function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<LandingPage />} />
        <Route exact path="/login" element={<Login />} />
        <Route exact path="/create" element={<Create />} />
        <Route exact path="/contact" element={<Contact />} />
        <Route exact path="/about" element={<AboutPage />} />
        <Route path="/agent" element={<AgentPage />} />
        <Route path="/airtimerate" element={<Airtime />} />
        <Route path="/bestdeal" element={<BestDeal />} />
        <Route path="/question" element={<Question />} />
        <Route path="/convertcash" element={<ConvertCash />} />
        <Route path="/deactivate" element={<AccountDeactivation />} />
      </Routes>
    </BrowserRouter>
  );
}
export default App;
