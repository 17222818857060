// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.common-div {
  display: flex;
  flex-direction: column;
  height: 417.9px;
  padding: 91px 0px;
  justify-content: space-between;
  line-height: 1.5em;
}
.download-link {
  display: flex;
  margin-left: -110px;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
}
.common-div h1 {
  color: #40196d;
  font-family: Rubik;
  font-size: 40px;
  font-style: normal;
  font-weight: 1100;
  line-height: 60px;
  margin-bottom: 20px;
}

@media (max-width: 1274px) {
  .common-div {
    display: flex;
    flex-direction: column;
  }

  .download-link {
    gap: 30px;
    margin-left: 3px;
    margin-top: 30px;
    /* margin-bottom: -70px; */
  }

  .common-div h1 {
    font-family: Rubik;
    font-size: 40px;
    font-weight: 500;
    line-height: 40px;
    margin-top: -100px;
    padding-left: -50px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/CommonDiv/CommonDiv.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,eAAe;EACf,iBAAiB;EACjB,8BAA8B;EAC9B,kBAAkB;AACpB;AACA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,SAAS;EACT,gBAAgB;AAClB;AACA;EACE,cAAc;EACd,kBAAkB;EAClB,eAAe;EACf,kBAAkB;EAClB,iBAAiB;EACjB,iBAAiB;EACjB,mBAAmB;AACrB;;AAEA;EACE;IACE,aAAa;IACb,sBAAsB;EACxB;;EAEA;IACE,SAAS;IACT,gBAAgB;IAChB,gBAAgB;IAChB,0BAA0B;EAC5B;;EAEA;IACE,kBAAkB;IAClB,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,kBAAkB;IAClB,mBAAmB;EACrB;AACF","sourcesContent":[".common-div {\n  display: flex;\n  flex-direction: column;\n  height: 417.9px;\n  padding: 91px 0px;\n  justify-content: space-between;\n  line-height: 1.5em;\n}\n.download-link {\n  display: flex;\n  margin-left: -110px;\n  justify-content: center;\n  gap: 20px;\n  margin-top: 20px;\n}\n.common-div h1 {\n  color: #40196d;\n  font-family: Rubik;\n  font-size: 40px;\n  font-style: normal;\n  font-weight: 1100;\n  line-height: 60px;\n  margin-bottom: 20px;\n}\n\n@media (max-width: 1274px) {\n  .common-div {\n    display: flex;\n    flex-direction: column;\n  }\n\n  .download-link {\n    gap: 30px;\n    margin-left: 3px;\n    margin-top: 30px;\n    /* margin-bottom: -70px; */\n  }\n\n  .common-div h1 {\n    font-family: Rubik;\n    font-size: 40px;\n    font-weight: 500;\n    line-height: 40px;\n    margin-top: -100px;\n    padding-left: -50px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
