import About from "../components/About/About";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
const AboutPage = () => {
  return (
    <div>
      <Header />
      <About />
      <Footer />
    </div>
  );
};
export default AboutPage;
