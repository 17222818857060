import "./AirtimeRate.css";
import CommonForm from "../CommonForm/CommonForm";
import CommonDiv from "../CommonDiv/CommonDiv";
const AirtimeRate = () => {
  return (
    <div className="airtime">
      <CommonDiv
        title="Best Airtime Rate"
        content="Irrespective of the network in Nigeria you are using, MegazApp provides
you with the best possible rates to topup your lines instantly with airtime 
at anytime of the day."
      />
      <CommonForm
        selectText="Choose Network"
        options={[""]}
        inputText="Enter the amount"
      />
    </div>
  );
};
export default AirtimeRate;
