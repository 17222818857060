import "./CommonForm.css";
const CommonForm = (props) => {
  return (
    <div className="form">
      <div className="container">
        <div className="purchase">
          <label>{props.selectText}:</label>
          <select>
            <option value="" selected>
              - {"Select Network"} -
            </option>
            <option>Mtn</option>
            <option>Glo</option>
            <option>Airtel</option>
            <option>9Mobile</option>
            {/* {props.options.map((x) => <option value="">- {x} -</option> )} */}
          </select>
        </div>
        <br />
        <div className="purchase">
          <label>{props.inputText}:</label>
          <input type="number" />
        </div>
        <div className="amount">
          Amount: <b>NGN 0.00</b>
        </div>
        <button className="purchase-btn">Buy Cheap Data</button>
      </div>
    </div>
  );
};
export default CommonForm;
