import React from "react";
import "./Footer.css";
import FooterLogo from "../FooterLogo/FooterLogo";
import FaceBook from "../../images/svgs/FT_FB.svg";
import Twitter from "../../images/svgs/FTwiter.svg";
import WhatsApp from "../../images/svgs/FT_WT.svg";
import Instagram from "../../images/svgs/FT_IG.svg";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="">
      {/* <div className="div-content">
        <div className="footer-elements">
          <FooterLogo />
          <ul className="element">
            <li className="top-text">Products</li>
            <li><Link to="/convertcash"className="foot-link">Airtime to cash</Link></li>
            <li><Link to="/airtimerate"className="foot-link">Airtime Top up</Link></li>
            <li><Link to="/bestdeal"className="foot-link">Data Bundles</Link></li>
          </ul>
          <ul className="element">
            <li className="top-text">Company</li>
            <li><Link to="/about"className="foot-link">About</Link></li>
            <li>Privacy Policy</li>
            <li>Term of Service</li>
          </ul>
          <ul className="element">
            <li className="top-text"> Agent</li>
            <li><Link to="/agent"className="foot-link">Apply Now!</Link></li>
          </ul>
          <ul className="element">
            <li className="">Contact</li>
            <div className="social_media">
              <div className="display-row">
                <img
                  className="vector" src={FaceBook} alt="facebook"/>
                <img
                  className="instagram"
                  alt="Instagram"
                  src={Instagram}
                />
              </div>
              <div className="display-row">
                <img
                  className="twitter-x"
                  alt="Twitter x"
                  src={Twitter}
                />
                <img
                  className="whats-app"
                  alt="Whats app"
                  src={WhatsApp}
                />
              </div>
            </div>
            <div className="display-col">
              <p className="top-text">call: 09134942498</p>
              <p>support@megazapp.com</p>
            </div>
          </ul>
        </div>
        <div className="div-py">
          <p className="p">
            © 2023 · MegazPay All Rights Reserved. · RC 3336921
          </p>
        </div>
      </div> */}
    </div>
  );
};
export default Footer;
