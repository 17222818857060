import Deal from "../components/Deal/Deal";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
const BestDeal = () => {
  return (
    <div>
      <Header />
      <Deal />
      <Footer />
    </div>
  );
};
export default BestDeal;
