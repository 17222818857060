import AirtimeRate from "../components/AirtimeRate/AirtimeRate";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
const Airtime = () => {
  return (
    <div>
      <Header />
      <AirtimeRate />
      <Footer />
    </div>
  );
};
export default Airtime;
