// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.div-col-span {
  align-items: center;
  display: flex;
  position: relative;
  width: max-content;
  gap: 10px;
}
.logo-name-title {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.div-col-span .yinka {
  height: 40px;
  margin-bottom: -91.5px;
  object-fit: cover;
  position: relative;
  width: 40px;
}

.div-col-span .text-wrapper {
  color: #40196d;
  font-size: 22px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 20px;
}

.div-col-span .div {
  color: #40196d;
  font-size: 14px;
  font-style: italic;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 20px;
}
`, "",{"version":3,"sources":["webpack://./src/components/FooterLogo/FooterLogo.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,aAAa;EACb,kBAAkB;EAClB,kBAAkB;EAClB,SAAS;AACX;AACA;EACE,aAAa;EACb,sBAAsB;EACtB,QAAQ;AACV;;AAEA;EACE,YAAY;EACZ,sBAAsB;EACtB,iBAAiB;EACjB,kBAAkB;EAClB,WAAW;AACb;;AAEA;EACE,cAAc;EACd,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,iBAAiB;AACnB;;AAEA;EACE,cAAc;EACd,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB;EACjB,iBAAiB;AACnB","sourcesContent":[".div-col-span {\n  align-items: center;\n  display: flex;\n  position: relative;\n  width: max-content;\n  gap: 10px;\n}\n.logo-name-title {\n  display: flex;\n  flex-direction: column;\n  gap: 5px;\n}\n\n.div-col-span .yinka {\n  height: 40px;\n  margin-bottom: -91.5px;\n  object-fit: cover;\n  position: relative;\n  width: 40px;\n}\n\n.div-col-span .text-wrapper {\n  color: #40196d;\n  font-size: 22px;\n  font-weight: 700;\n  letter-spacing: 0;\n  line-height: 20px;\n}\n\n.div-col-span .div {\n  color: #40196d;\n  font-size: 14px;\n  font-style: italic;\n  font-weight: 400;\n  letter-spacing: 0;\n  line-height: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
