import "./Agent.css";
import CommonDiv from "../CommonDiv/CommonDiv";
const Agent = () => {
  return (
    <div className="agent">
      <CommonDiv
        title="Become an Agent"
        content="Earn substantial commission and offer your customers dependable service as a MegazApp agent. As an agent you have access to all the services. You also get better rates for most of the services. Their are different levels on the MegazApp agent hierarchy. The more you deposit the higher your level and then the better the price discount you get.  "
      />
      <div className="form">
        <div className="container">
          <div className="purchase">
            <label>Agent Level:</label>
            <select>
              <option value="" selected>
                - Select a Level -
              </option>
            </select>
          </div>
          <br />
          <div className="purchase">
            <select>
              <option value="" selected>
                - Percentage discount in price -
              </option>
            </select>
          </div>
          <div className="amount">
            Amount: <b>NGN 0.00</b>
          </div>
          <button className="purchase-btn">Get Started Now!</button>
        </div>
      </div>
    </div>
  );
};
export default Agent;
