// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.airtime {
  margin-top: 200px;
  margin-bottom: 150px;
  padding-left: 85px;
  padding-right: 100px;
  display: flex;
  justify-content: center;
  gap: 150px;
}


@media (max-width: 1274px) {
  .airtime {
    display: flex;
    flex-direction: column;
    padding-left: 30px;
    padding-right: 40px;
    margin-top: 150px;
    margin-bottom: -100px;
    gap: 100px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/AirtimeRate/AirtimeRate.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,oBAAoB;EACpB,kBAAkB;EAClB,oBAAoB;EACpB,aAAa;EACb,uBAAuB;EACvB,UAAU;AACZ;;;AAGA;EACE;IACE,aAAa;IACb,sBAAsB;IACtB,kBAAkB;IAClB,mBAAmB;IACnB,iBAAiB;IACjB,qBAAqB;IACrB,UAAU;EACZ;AACF","sourcesContent":[".airtime {\n  margin-top: 200px;\n  margin-bottom: 150px;\n  padding-left: 85px;\n  padding-right: 100px;\n  display: flex;\n  justify-content: center;\n  gap: 150px;\n}\n\n\n@media (max-width: 1274px) {\n  .airtime {\n    display: flex;\n    flex-direction: column;\n    padding-left: 30px;\n    padding-right: 40px;\n    margin-top: 150px;\n    margin-bottom: -100px;\n    gap: 100px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
