import "./Service.css";
import IPhone from "../containers/images/iPhone 14.png";
import Internet from "../containers/images/Internet Connection.png";
import Anchor from "../containers/images/Anchor.png";
import Bill from "../containers/images/Paid_Bill.png";
import { Link } from "react-router-dom";

const Service = () => (
  <div className="service">
    <h2 className="service-header">Services</h2>

    <div className="our-services">
      <div className="service-box">
        <img className="service-icon" src={Anchor} id="anchor" alt="anchor" />
        <h3>Instant Airtime to Cash</h3>
        <p className="service-text">
          <br />
          Convert your airtime to cash for all
          <br />
          networks in Nigeia for very good rates.
          <br />
          <br />
        </p>
          <button id="btn">
          <Link to="/convertcash" className="lik">
            Read More &#11166; 
          </Link>
            </button>
      </div>

      <div className="service-box">
        <img className="service-icon" src={IPhone} id="iphone" alt="IPhone" />
        <h3>Airtime Topup</h3>
        <p className="service-text">
          <br />
          In a few easy step you can topup your airtime
          <br />
          for all networks in Nigeria and stay connected <br />
          with your friends and family.
        </p>
        <button id="btn"> 
        <Link to="/airtimerate" className="lik">
        Read More &#11166;
        </Link>
        </button>
      </div>

      <div className="service-box">
        <img
          className="service-icon"
          src={Internet}
          id="internet"
          alt="internet"
        />
        <h3>Data Purchase</h3>
        <p className="service-text">
          <br />
          Stay connected with internet 24/7 with our <br />
          data deals irrespective of the value for all <br />
          networks in Nigeria.
        </p><button id="btn">
        <Link to="/bestdeal" className="lik">
          Read More &#11166; 
        </Link>
          </button>
        </div>

      <div className="bill-container">
        <img className="service-icon" src={Bill} id="bill" alt="bill" />
        <h3>Bill Payments</h3>
        <p className="service-text">
          <br />
          MegazApp has streamlined the bill-paying <br />
          process making it more convinient, effcient <br /> and secure while
          also offering valuable <br />
          financial management features to help users <br />
          stay in control of there finances.
        </p>
      </div>
    </div>
  </div>
);

export default Service;
