import "./Choose.css";
import Grading from "../containers/images/Grading.png";
import Group from "../containers/images/Group.png";
import Speed from "../containers/images/Speed.png";
import Money from "../containers/images/Money.png";
import Head from "../containers/images/Head.png";
import Rate from "../containers/images/Rate.png";

const Choose = () => (
  <div className="choose">
    <h2 className="choose-header">
      Why Choose <span id="colored-tet"> MegazApp</span>?
      {/* Why Choose MegazApp? */}
    </h2>

    <div className="our-choose">
      <div className="choose-box">
        <img className="choose-icon" src={Grading} id="grading" alt="grading" />
        <h3>Easy to Use</h3>
        <p className="choose-text">
          <br />
          Say goodbye to the tiresome task of payiong bills. MegazApp interface
          is designed in such a way that gives users a hassle free experience.
        </p>
      </div>

      <div className="choose-box">
        <img className="choose-icon" src={Group} id="group" alt="group" />
        <h3>Secure</h3>
        <p className="choose-text">
          <br />
          MegazApp is designed to protect user's sensitive financial
          information, transaction and data from unauthorized access and cyber
          threats.
        </p>
      </div>

      <div className="choose-box">
        <img className="choose-icon" src={Speed} id="speed" alt="speed" />
        <h3>Fast and Reliable</h3>
        <p className="choose-text">
          <br />
          In a world where time and effciency is highly valuable, MegazApp
          provides a solution which leads to incresed productivity in this
          regards.
        </p>
      </div>

      <div className="choose-box">
        <img className="choose-icon" src={Money} id="money" alt="money" />
        <h3>Earn Rewards</h3>
        <p className="choose-text">
          <br />
          You can earn substantial financial rewards as a MegazApp agent.
          <br />
          <br />
        </p>
        <a href="/agent">
          {" "}
        <button id="btn">
          Read More &#11166; </button>
          </a>
      </div>

      <div className="choose-box">
        <img className="choose-icon" src={Head} id="head" alt="head" />
        <h3>Customer Support</h3>
        <p className="choose-text">
          <br />
          Our customer support team is available 24/7 across different social
          media platforms to assist you and provide furthe information about our
          services.
        </p>
        <a href="/contact">
          {" "}
          <button id="btn">Contact Us &#11166; </button>{" "}
        </a>
      </div>

      <div className="choose-box">
        <img className="choose-icon" src={Rate} id="rate" alt="rate" />
        <h3>Affordable Rates</h3>
        <p className="choose-text">
          <br />
          MegazApp provides you with the best possible rate to convert your
          airtime to cash and to pay for other bills.
        </p>
      </div>
    </div>
  </div>
);
export default Choose;
